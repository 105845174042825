import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import useRecentEvent from './queries/useRecentEvent';
import usePastEvent from './queries/usePastEvent';
import useWeeklySabha from './queries/useWeeklySabha';

export default function Events() {
  const {
    title: recentEventTitle,
    date: recentEventDate,
    src: recentEventImg,
    path: recentEventPath,
  } = useRecentEvent();
  const {
    title: weeklySabhaTitle,
    date: weeklySabhaDate,
    src: weeklySabhaImg,
  } = useWeeklySabha();
  const {
    title: pastEventTitle,
    date: pastEventDate,
    src: pastEventImg,
    path: pastEventPath,
  } = usePastEvent();

  return (
    <section
      className='py-10 bg-gray-300'
      style={{
        marginTop: '-8px',
      }}
    >
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap items-center justify-center'>
          <div className='w-full md:w-6/12  lg:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg text-center'>
              <div className='px-4 py-5 flex-auto'>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-orange-200 uppercase last:mr-0 mr-1'>
                  Darshan Timings
                </span>
                <div className='flex flex-wrap justify-center mt-2'>
                  <GatsbyImage
                    image={recentEventImg.childImageSharp.gatsbyImageData}
                    style={{ height: '100%', width: '100%' }}
                    className='shadow-lg rounded align-middle border-none'
                    alt='Darshan Timings'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='w-full md:w-6/12  lg:w-4/12 px-4 text-center'>
            <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
              <div className='px-4 py-5 flex-auto'>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-orange-200 uppercase last:mr-0 mr-1'>
                  Mandir Address
                </span>
                <div className='flex flex-wrap justify-center mt-3'>
                  <GatsbyImage
                    image={weeklySabhaImg.childImageSharp.gatsbyImageData}
                    style={{ height: '100%', width: '100%' }}
                    className='shadow-lg rounded align-middle border-none'
                    alt='Mandir Address'
                  />
                </div>
                <hr className='my-4 border-gray-400' />
                <p className='mt-2 mb-2 text-gray-700'>
                  <strong>540 South Martingale Road</strong>
                </p>
                <p className='mb-4 text-gray-700'>
                  <strong>Schaumburg, IL 60193</strong>
                </p>
                <hr className='my-4 border-gray-400' />
                <p className='mt-2 mb-4 text-gray-600'>
                  Tel:{' '}
                  <strong>
                    <a href='tel:8473805168'>(847) 380 5168</a>
                  </strong>{' '}
                </p>
                <p className='mt-2 mb-4 text-gray-600'>
                  Email:{' '}
                  <strong>
                    <a href='mailto:temple@ydschicago.org'>
                      temple@ydschicago.org
                    </a>
                  </strong>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
